var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: "\n      box_seat_class fit-content overflow-hidden cursor-pointer my-25\n      ".concat(_vm.seat.isRight ? 'border-seat-right ' : 'border-seat-left', "\n    ")
  }, [_c('b-button', {
    class: "seat_class text-white text-center rounded-lg fw-700 w-100\n      ",
    attrs: {
      "id": "button-ticket-".concat(_vm.seat.nameId),
      "variant": _vm.resolveVariantSeat(_vm.seat)
    },
    on: {
      "click": function click($event) {
        !_vm.seat.notExist && _vm.handleClickSeat(_vm.seat);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.seat.nameId) + " "), _c('b-tooltip', {
    attrs: {
      "target": "button-ticket-".concat(_vm.seat.nameId),
      "triggers": "hover focus",
      "placement": "top",
      "boundary": "window",
      "variant": _vm.resolveVariantSeat(_vm.seat)
    }
  }, [_c('div', {
    staticClass: "font-medium-1 text-white fw-700"
  }, [_vm.isAvailableSeat(_vm.seat) ? [_c('div', {
    staticClass: "mb-50"
  }, [_vm._v(" Chỗ trống ")]), _c('div', [_vm._v("Giá: " + _vm._s(_vm.formatCurrency(_vm.seat.totalPrice * 1000)) + " VND")])] : _vm.resolveVariantSeat(_vm.seat) === 'warning' ? [_c('div', [_vm._v(" Chỗ đã chọn ")]), _c('div', [_vm._v("Giá: " + _vm._s(_vm.formatCurrency(_vm.seat.totalPrice * 1000)) + " VND")])] : [_vm._v(" Chỗ đã bán ")]], 2)])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }